import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Button, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"quarkly-title": "CTA/Lead/Form-6"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "56px 84px",
			"margin": "0px 0px 40px 0px",
			"lg-grid-template-columns": "1fr",
			"sm-grid-gap": "36px 24px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"lg-padding": "0px 150px 0px 0px",
			"md-padding": "0px 0 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 700 42px/1.2 --fontFamily-sans",
			"children": "Зв'яжіться з нами"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"font": "normal 300 22px/1.5 --fontFamily-sansHelvetica",
			"children": "Вирушайте у подорож на мотоциклі разом з нами в мотошколі ГудОк."
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"background": "--color-dark",
			"padding": "12px 28px 12px 28px",
			"border-radius": "4px",
			"font": "normal 400 18px/1.5 --fontFamily-sans",
			"margin": "0px 0px 60px 0px",
			"sm-margin": "0px 0px 40px 0px",
			"href": "/contacts",
			"type": "link",
			"text-decoration-line": "initial"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://solarioxa.com/img/3.jpg",
			"display": "block",
			"width": "100%",
			"height": "100%",
			"object-fit": "cover",
			"border-radius": "25px",
			"margin": "0px 0px 0px 0",
			"lg-order": "-1",
			"lg-height": "500px",
			"md-height": "400px"
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
				<Button {...override("button")}>
					Знайти Нас
				</Button>
			</Box>
			<Image {...override("image")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;